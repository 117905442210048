import http from '../../http';

export function getDealbusinessPage (params) {
    return http({
        url: "/api/business/businessList",
        method: "get",
        params:params
    })
}

export function businessDetail (params) {
    return http({
        url: "/api/business/businessDetail",
        method: "get",
        params:params
    })
}

export function businessAuditList (params) {
    return http({
        url: "/api/business/businessAuditList",
        method: "get",
        params:params
    })
}

export function businessEndAuditList (params) {
    return http({
        url: "/api/business/businessEndAuditList",
        method: "get",
        params:params
    })
}




export function businessStatisticsDay (params) {
    return http({
        url: "/api/business/businessStatisticsDay",
        method: "get",
        params:params
    })
}


export function businessStatisticsExcel (params) {
    return http({
        url: "/api/business/businessStatisticsExcel",
        method: "get",
        params:params
    })
}

export function allNormalUserName (params) {
    return http({
        url: "/api/user/allNormalUserName",
        method: "get",
        params:params
    })
}


export function deleteBusiness (data) {
    return http({
        url: "/api/business/deleteBusiness",
        method: "delete",
        data:data
    })
}

export function businessCreate (data) {
    return http({
        url: "/api/business/create",
        method: "post",
        data:data
    })
}

export function unSuccessful (data) {
    return http({
        url: "/api/business/unSuccessful",
        method: "post",
        data:data
    })
}



export function audit (data) {
    return http({
        url: "/api/base/audit",
        method: "post",
        data:data
    })
}




export function completionApplication (data) {
    return http({
        url: "/api/business/completionApplication",
        method: "post",
        data:data
    })
}

export function businessEdit (data) {
    return http({
        url: "/api/business/update",
        method: "post",
        data:data
    })
}


